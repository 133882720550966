const PortfolioData = [
  {
    id: 1,
    title: "Sean Bradbury - Portfolio",
    tech: "Design, Gatsby, Javascript",
    viewSiteLink: "https://www.seanbradbury.com",
    viewSiteCode: "https://github.com/Sean-Bradbury/Portfolio",
    image: "../img/portfolio/portfolio-website.png",
  },
  {
    id: 2,
    title: "JW Trees",
    tech: "Design, HTML, SCSS, JS, JQuery",
    viewSiteLink: "https://jwtrees.netlify.app/",
    viewSiteCode: "https://www.google.com",
    image: "../img/portfolio/jw-website.png",
  },
  {
    id: 3,
    title: "Entertainment Web App",
    tech: "React, Typescript, SASS, HTML",
    viewSiteLink: "https://sb-entertainment-app.netlify.app/",
    viewSiteCode: "https://github.com/Sean-Bradbury/entertainment-web-app",
    image: "../img/portfolio/entertainment-app.png",
  },
  {
    id: 4,
    title: "Interactive Comment Section",
    tech: "React, Javascript, SASS, HTML",
    viewSiteLink: "https://sb-comment-section.netlify.app/",
    viewSiteCode:
      "https://github.com/Sean-Bradbury/Interactive_Comment_Section",
    image: "../img/portfolio/sb_comment_section.png",
  },
  {
    id: 5,
    title: "Rest Countries API",
    tech: "React, Javascript, API, CSS, HTML",
    viewSiteLink: "https://stupefied-ride-ab7e18.netlify.app/",
    viewSiteCode: "https://github.com/Sean-Bradbury/rest-countries-api",
    image: "../img/portfolio/rest-countries-api.png",
  },
  {
    id: 6,
    title: "H20 Property Services",
    tech: "Design, Logo, HTML, PHP, Bootstrap",
    viewSiteLink: "http://www.h2oplumbingandgas.co.uk/",
    viewSiteCode: "https://github.com/Sean-Bradbury/H2O-PlumbingAndGas",
    image: "../img/portfolio/h2o-website.png",
  },
  {
    id: 7,
    title: "Slumba",
    tech: "Design, Logo, HTML, SCSS, JS, JQuery",
    viewSiteLink: "https://slumba.netlify.app/",
    viewSiteCode: "https://github.com/Sean-Bradbury/slumba",
    image: "../img/portfolio/slumba-website.png",
  },
  {
    id: 8,
    title: "Bookmark",
    tech: "HTML, SCSS, JS, JQuery, Form Validation",
    viewSiteLink: "https://peaceful-lamport-ff3a3e.netlify.app/",
    viewSiteCode: "https://github.com/Sean-Bradbury/Bookmark-LP",
    image: "../img/portfolio/bookmark-landingpage.png",
  },
  {
    id: 9,
    title: "Learn To Code",
    tech: "HTML, SCSS, Form Validation",
    viewSiteLink: "https://amazing-swirles-1b3f9f.netlify.app/",
    viewSiteCode: "https://github.com/Sean-Bradbury/Intro-Component-SignUp",
    image: "../img/portfolio/learntocode-landingpage.png",
  },
]

export default PortfolioData
